<template>
  <div>
    <b-sidebar
      id="sidebar-condition"
      ref="sidebarCondition"
      v-model="isShowSidebar"
      aria-labelledby="sidebar-condition-header"
      right
      backdrop
      shadow
      no-header
      width="80vw"
    >
      <div class="px-3">
        <b-row class="header-filter">
          <b-col class="text-filter"> Conditions </b-col>
          <b-col class="text-right">
            <button class="btn-clear" @click.prevent="hide">x</button>
          </b-col>
        </b-row>
        <div v-if="isLoading">
          <OtherLoading />
        </div>
        <div v-else>
          <div class="my-3">
            <b-form-radio-group v-model="isSelected" @change="setCondition">
              <b-row>
                <b-col
                  ><b-form-radio :value="2">Unconditional</b-form-radio></b-col
                >
                <b-col><b-form-radio :value="1">Custom</b-form-radio></b-col>
              </b-row>
            </b-form-radio-group>
          </div>
          <div v-if="isSelected === 1">
            <div v-for="(group, n) of form" :key="'group-' + n">
              <div class="card-condition-container">
                <div class="content-between">
                  <div class="card-group-title">Group #{{ n + 1 }}</div>

                  <b-button
                    variant="light"
                    v-if="form.length > 1"
                    @click="removeGroup(n)"
                  >
                    <b-icon size="sm" icon="trash-fill" />
                  </b-button>
                </div>
                <b-row class="mt-3">
                  <b-col md="4">
                    <label>User Type <span class="text-error">*</span></label>
                  </b-col>
                  <b-col md="8">
                    <label
                      >Show Information <span class="text-error">*</span></label
                    >
                  </b-col>
                </b-row>

                <div
                  v-for="(item, index) in group.condition"
                  :key="index"
                  class="mb-3"
                >
                  <b-row>
                    <b-col md="4">
                      <InputSelectAutomation
                        v-bind:options="conditionGroup[n]"
                        :value="item.condition_type_id"
                        :v-model="item.condition_type_id"
                        :indexCondition="index"
                        :indexGroup="n"
                        @onDataChange="selectCondition"
                        valueField="id"
                        :v="
                          $v.form.$each.$iter[n].condition.$each.$iter[index]
                            .condition_type_id
                        "
                        :isValidate="
                          $v.form.$each.$iter[n].condition.$each.$iter[index]
                            .condition_type_id.$error
                        "
                        textField="name"
                      >
                        <template v-slot:option-first>
                          <b-form-select-option value="" disabled
                            >-- Please Select Type --</b-form-select-option
                          >
                        </template>
                      </InputSelectAutomation>
                    </b-col>
                    <b-col md="8">
                      <b-row
                        class="no-gutters"
                        v-if="item.condition_type_id === 10"
                      >
                        <b-col md="10" class="justify-content-start">
                          <UploadFileSendMessage
                            textFloat="File"
                            placeholder="Please Choose File"
                            format="excel"
                            name="file"
                            text="*Please upload only file .xlsx less than 10 MB"
                            isRequired
                            v-on:onFileChange="onFileChange"
                            :indexCondition="index"
                            :indexGroup="n"
                            v-on:delete="deleteFile"
                            :fileName="item.condition_value"
                            id="uploadfile_sendmessage"
                            :v="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].condition_value
                            "
                            :isValidate="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].condition_value.$error
                            "
                          />
                        </b-col>
                        <b-col md="2" class="p-0">
                          <b-button
                            variant="light"
                            v-if="
                              form.length > 1 || form[0].condition.length > 1
                            "
                            @click="removeCondition(index, n)"
                          >
                            <b-icon size="sm" icon="trash-fill" />
                          </b-button>
                        </b-col>
                      </b-row>

                      <!--  -->

                      <b-row
                        v-else-if="
                          item.condition_type_id != '' &&
                          getTypeOfOptions(item.condition_type_id) == 'Dropdown'
                        "
                      >
                        <b-col
                          :md="
                            item.condition_type_id == 17 ||
                            item.condition_type_id == 27
                              ? '6'
                              : '10'
                          "
                        >
                          <InputDropdown
                            v-bind:options="getOptions(item.condition_type_id)"
                            :value="item.condition_value"
                            :v="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].condition_value
                            "
                            :isValidate="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].condition_value.$error
                            "
                            :noPleaseSelect="noPleaseSelect"
                            v-model="item.condition_value"
                            :indexCondition="index"
                            :indexGroup="n"
                            @onDataChange="selectPrivilegeCode"
                            valueField="id"
                            textField="name"
                            :name="'test' + '-' + index + '-' + n"
                          >
                          </InputDropdown>
                        </b-col>
                        <b-col
                          v-if="
                            item.condition_type_id == 17 ||
                            item.condition_type_id == 27
                          "
                          md="4"
                        >
                          <UploadFileV2
                            textFloat=""
                            placeholder="Please Choose File"
                            format="excel"
                            name="file"
                            text=""
                            :data="item"
                            v-on:onFileChange="onImportFileChange"
                            :fileName="fileUpload.name"
                            :showFileName="false"
                            :indexCondition="index"
                            :indexGroup="n"
                            v-model="fileUpload.excel_file"
                            id="uploadfile"
                        /></b-col>
                        <b-col md="2" class="p-0">
                          <b-button
                            variant="light"
                            v-if="
                              form.length > 1 || form[0].condition.length > 1
                            "
                            @click="removeCondition(index, n)"
                          >
                            <b-icon size="sm" icon="trash-fill" />
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row
                        v-else-if="
                          item.condition_type_id != '' &&
                          getTypeOfOptions(item.condition_type_id) == 'int'
                        "
                      >
                        <b-col md="4">
                          <InputSelectAutomation
                            v-bind:options="operatorList"
                            :value="item.operation_type_id"
                            :v="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].operation_type_id
                            "
                            :isValidate="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].operation_type_id.$error
                            "
                            :noPleaseSelect="noPleaseSelect"
                            v-model="item.operation_type_id"
                            :indexCondition="index"
                            :indexGroup="n"
                            @onDataChange="selectOperation"
                            valueField="id"
                            textField="name"
                          >
                            <template v-slot:option-first>
                              <b-form-select-option value="" disabled
                                >-- Please Select Type --</b-form-select-option
                              >
                            </template>
                          </InputSelectAutomation>
                        </b-col>
                        <template v-if="item.operation_type_id == 6">
                          <b-col md="3">
                            <b-form-input
                              v-model="item.condition_value"
                              placeholder="value"
                              type="number"
                              :class="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                                  ? 'border-error'
                                  : ''
                              "
                            ></b-form-input>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                          <b-col md="3">
                            <b-form-input
                              v-model="item.between_value"
                              placeholder="value"
                              type="number"
                              :class="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                                  ? 'border-error'
                                  : ''
                              "
                            ></b-form-input>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col md="6">
                            <b-form-input
                              v-model="item.condition_value"
                              placeholder="value"
                              type="number"
                              :class="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                                  ? 'border-error'
                                  : ''
                              "
                            ></b-form-input>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                        </template>
                        <b-col md="2" class="p-0">
                          <b-button
                            variant="light"
                            v-if="
                              form.length > 1 || form[0].condition.length > 1
                            "
                            @click="removeCondition(index, n)"
                          >
                            <b-icon size="sm" icon="trash-fill" />
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row
                        v-else-if="
                          item.condition_type_id != '' &&
                          getTypeOfOptions(item.condition_type_id) == 'Datetime'
                        "
                      >
                        <b-col md="4">
                          <InputSelectAutomation
                            v-bind:options="operatorList"
                            :value="item.operation_type_id"
                            :v="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].operation_type_id
                            "
                            :isValidate="
                              $v.form.$each.$iter[n].condition.$each.$iter[
                                index
                              ].operation_type_id.$error
                            "
                            :noPleaseSelect="noPleaseSelect"
                            v-model="item.operation_type_id"
                            :indexCondition="index"
                            :indexGroup="n"
                            @onDataChange="selectOperation"
                            valueField="id"
                            textField="name"
                          >
                            <template v-slot:option-first>
                              <b-form-select-option value="" disabled
                                >-- Please Select Type --</b-form-select-option
                              >
                            </template>
                          </InputSelectAutomation>
                        </b-col>
                        <template v-if="item.operation_type_id == 6">
                          <b-col md="3">
                            <div class="input-container">
                              <datetime
                                type="datetime"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'date-picker mb-0 border-error'
                                    : 'date-picker mb-0'
                                "
                                :input-style="styleDatetime"
                                v-model="item.condition_value"
                                placeholder="DD/MM/YYYY (HH:MM)"
                                format="dd/MM/yyyy (HH:mm)"
                                value-zone="Asia/Bangkok"
                                ref="transferDateStart"
                              >
                              </datetime>
                              <div
                                :class="'icon-primary text-right'"
                                @click="$refs.transferDateStart.isOpen = true"
                              >
                                <font-awesome-icon
                                  icon="calendar-alt"
                                  :class="'pointer color-primary'"
                                  color="#B41BB4"
                                />
                              </div>
                            </div>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                          <b-col md="3">
                            <div class="input-container">
                              <datetime
                                type="datetime"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'date-picker mb-0 border-error'
                                    : 'date-picker mb-0'
                                "
                                :input-style="styleDatetime"
                                v-model="item.between_value"
                                placeholder="DD/MM/YYYY (HH:MM)"
                                format="dd/MM/yyyy (HH:mm)"
                                value-zone="Asia/Bangkok"
                                ref="transferDateStart"
                              >
                              </datetime>
                              <div
                                :class="'icon-primary text-right'"
                                @click="$refs.transferDateStart.isOpen = true"
                              >
                                <font-awesome-icon
                                  icon="calendar-alt"
                                  :class="'pointer color-primary'"
                                  color="#B41BB4"
                                />
                              </div>
                            </div>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                        </template>
                        <template v-else>
                          <b-col md="6">
                            <div class="input-container">
                              <datetime
                                type="datetime"
                                :class="
                                  $v.form.$each.$iter[n].condition.$each.$iter[
                                    index
                                  ].condition_value.$error
                                    ? 'date-picker mb-0 border-error'
                                    : 'date-picker mb-0'
                                "
                                :input-style="styleDatetime"
                                v-model="item.condition_value"
                                placeholder="DD/MM/YYYY (HH:MM)"
                                format="dd/MM/yyyy (HH:mm)"
                                value-zone="Asia/Bangkok"
                                ref="transferDateStart"
                              >
                              </datetime>
                              <div
                                :class="'icon-primary text-right'"
                                @click="$refs.transferDateStart.isOpen = true"
                              >
                                <font-awesome-icon
                                  icon="calendar-alt"
                                  :class="'pointer color-primary'"
                                  color="#B41BB4"
                                />
                              </div>
                            </div>
                            <div
                              v-if="
                                $v.form.$each.$iter[n].condition.$each.$iter[
                                  index
                                ].condition_value.$error
                              "
                              class="text-error"
                            >
                              Please input.
                            </div>
                          </b-col>
                        </template>
                        <b-col md="2" class="p-0">
                          <b-button
                            variant="light"
                            v-if="
                              form.length > 1 || form[0].condition.length > 1
                            "
                            @click="removeCondition(index, n)"
                          >
                            <b-icon size="sm" icon="trash-fill" />
                          </b-button>
                        </b-col>
                      </b-row>

                      <b-row v-else>
                        <b-col md="10"> </b-col>
                        <b-col md="2" class="p-0">
                          <b-button
                            variant="light"
                            v-if="
                              form.length > 1 || form[0].condition.length > 1
                            "
                            @click="removeCondition(index, n)"
                          >
                            <b-icon size="sm" icon="trash-fill" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div class="mt-3">
                  <button
                    @click.prevent="addRuleCondition(n)"
                    class="btn-add"
                    :disabled="group.disabledCreate"
                  >
                    <font-awesome-icon icon="plus-square" class="mr-2" />
                    <span class="add-new">Create a New Conditions</span>
                  </button>
                </div>
              </div>
              <b-row
                v-if="form.length > 1 && n + 1 != form.length"
                class="mb-2"
              >
                <b-col cols="4">
                  <InputSelectAutomation
                    v-bind:options="conditonOperator"
                    :value="group.operation_type"
                    :noPleaseSelect="noPleaseSelect"
                    v-model="group.operation_type"
                    @onDataChange="setOperation"
                    :indexGroup="n"
                    valueField="value"
                    textField="text"
                  >
                    <template v-slot:option-first>
                      <b-form-select-option value="" disabled
                        >-- Please Select Type --</b-form-select-option
                      >
                    </template>
                  </InputSelectAutomation></b-col
                >

                <b-col cols="12" class="text-error" v-if="errorGroup[n]"
                  >Please select value.</b-col
                >
              </b-row>
            </div>
          </div>
          <div class="mt-3" v-if="isSelected === 1">
            <button @click.prevent="addGroupConditions" class="btn-add">
              <font-awesome-icon icon="plus-square" class="mr-2" />
              <span class="add-new">Create a New Group</span>
            </button>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-content-between shadow align-items-center">
          <div class="w-100">
            <b-button
              block
              variant="dark"
              class="text-light rounded-pill"
              @click="hide"
              :disabled="isLoading"
            >
              Cancel
            </b-button>
          </div>
          <div class="w-100">
            <b-button
              block
              variant="light"
              :disabled="isLoading"
              class="text-body rounded-pill btn-main"
              @click.prevent="submitCondition"
            >
              Save
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import InputSelectAutomation from "@/components/inputs/InputSelectAutomation";
import InputDropdown from "@/components/inputs/InputAutomationDropdown";
import DisplayCampaignField from "@/components/automation/component/DisplayCampaignField";
import UploadFileSendMessage from "@/components/automation/segmentation/UploadFileSendMessage";
import OtherLoading from "@/components/loading/OtherLoading";
import { required, requiredIf, url } from "vuelidate/lib/validators";
export default {
  components: {
    InputSelectAutomation,
    UploadFileSendMessage,
    OtherLoading,
    DisplayCampaignField,
    InputDropdown,
    UploadFileV2,
  },
  props: {
    privilegeList: {
      required: true,
      // type: Array,
    },
    serviceList: {
      required: true,
    },
    branchList: {
      required: true,
    },
    productList: {
      required: true,
    },
    campaignList: {
      required: true,
    },
    stampList: {
      required: false,
      // type: Array,
    },
    conditionList: {
      required: true,
      type: Array,
    },
    memberLevel: {
      required: true,
      type: Array,
    },
    gender: {
      required: true,
      type: Array,
    },
    operatorList: {
      required: true,
      type: Array,
    },
    listCondition: {
      required: false,
      type: [Array, undefined],
    },
    v: {
      required: true,
      type: Object,
    },
    selected: {
      required: true,
      type: Number,
    },
    formMain: {
      required: false,
      type: Object,
    },
    campaignFieldConditions: {
      required: false,
      type: Array,
    },
  },

  data() {
    return {
      isShowSidebar: false,
      condition_type_id: 0,
      noPleaseSelect: true,
      isSelected: this.selected,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      filename: "",
      fileBase64: "",
      isLoading: false,
      form: [],
      conditonOperator: [
        { text: "And", value: "and" },
        { text: "Or", value: "or" },
        { text: "And Not", value: "not" },
      ],
      conditionGroup: [],
      errorGroup: [],
      fileUpload: {
        name: "",
        excel_file: "",
      },
    };
  },
  validations: {
    form: {
      $each: {
        condition: {
          $each: {
            condition_type_id: {
              required: requiredIf(function (item) {
                return !item.condition_type_id;
              }),
            },
            operation_type_id: {
              required: requiredIf(function (item) {
                return !item.operation_type_id;
              }),
            },
            condition_value: {
              required: requiredIf(function (item) {
                return item.condition_type_id && !item.condition_value;
              }),
            },
          },
        },
      },
    },
  },
  watch: {
    listCondition(val) {
      this.form = JSON.parse(JSON.stringify(val));
    },
  },
  methods: {
    getTypeOfOptions(id) {
      return this.conditionList.find((el) => el.id == id).type;
    },
    getOptions(id) {
      switch (id) {
        case 1:
          return this.memberLevel;
        case 2:
          return this.gender;
        case 7:
          return this.privilegeList;
        case 11:
          return this.stampList;
        case 18:
          return this.campaignList;
        case 17:
          return this.productList;
        case 27:
          return this.serviceList;
        case 16:
          return this.branchList;
        case 23:
          return this.branchList;
        case 25:
          return this.branchList;
        default:
          break;
      }
    },
    show() {
      // this.conditionGroup = [];
      if (this.isSelected === 1) {
        this.$v.$reset();
        let tempObj = [...this.listCondition];

        if (tempObj[0].condition.length == 0) {
          tempObj[0].condition.push({
            condition_type_id: "",
            operation_type_id: "",
            condition_value: "",
            condition_type_name: "",
          });
        }

        this.form = JSON.parse(JSON.stringify(tempObj));

        this.disabledOptions();
      }
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    disabledOptions() {
      var condition = this.form;
      var groupCondtion = [];
      for (const o of condition) {
        var c = [];
        var con = JSON.parse(
          JSON.stringify(
            this.conditionList.map((el) => {
              return { ...el, disabled: false };
            })
          )
        );
        for (const a of con) {
          if (o.condition.find((el) => el.condition_type_id == a.id)) {
            a.disabled = true;
          } else {
            a.disabled = false;
          }
        }
        groupCondtion.push(con);
      }
      this.conditionGroup = [...groupCondtion];
    },
    addRuleCondition(index) {
      this.form[index].condition.push({
        condition_type_id: "",
        operation_type_id: "",
        condition_value: "",
        condition_type_name: "",
      });
      this.disabledOptions();
    },
    async addGroupConditions() {
      await this.form.push({
        group: this.form.length + 1,
        import_condition: "",
        operation_type: "",
        disabledCreate: false,
        condition: [
          {
            condition_type_id: "",
            operation_type_id: "",
            condition_value: "",
            condition_type_name: "",
          },
        ],
      });

      this.disabledOptions();
    },
    selectOperationTypeId(...value) {
      this.form[value[1]].operation_type_id = value[0];
      this.form[value[1]].operation_type_name = value[2];
    },
    selectCondtionValue(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
      this.form[value[3]].condition[value[1]].operation_type_id = 3;
    },
    selectMemberLevel(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
      this.form[value[3]].condition[value[1]].operation_type_id = 3;
    },
    selectPrivilegeCode(...value) {
      this.form[value[3]].condition[value[1]].condition_value = value[0];
      this.form[value[3]].condition[value[1]].operation_type_id = 3;
    },
    removeCondition(index, group) {
      if (this.form[group].condition.length == 1 && this.form.length > 1) {
        this.form[group - 1].operation_type = "";
        return this.form.splice(group, 1);
      }
      this.form[group].condition.splice(index, 1);
      this.disabledOptions();
    },
    removeGroup(group) {
      this.form.splice(group, 1);
      var i = 1;
      for (const f of this.form) {
        f.group = i;
        i++;
      }
      if (group == 0) {
        this.form[group].operation_type = "";
      } else {
        this.form[group - 1].operation_type = "";
      }
      return this.disabledOptions();
    },

    async selectCondition(...value) {
      this.form[value[3]].condition[value[1]].condition_type_id = value[0];
      this.form[value[3]].condition[value[1]].condition_value = "";
      this.form[value[3]].condition[value[1]].condition_type_name = value[2];
      this.form[value[3]].disabledCreate = false;

      // if selected TypeId 10 -> remove other conditions
      let filterTypeId10 = await this.form[value[3]].condition.filter(
        (el) => el.condition_type_id === 10
      );
      if (filterTypeId10.length > 0) {
        this.form[value[3]].disabledCreate = true;
        this.form[value[3]].condition = filterTypeId10;
      }

      this.disabledOptions();
    },
    selectOperation(...value) {
      this.form[value[3]].condition[value[1]].operation_type_id = value[0];
      this.form[value[3]].condition[value[1]].operation_type_name = value[2];
    },
    async submitCondition() {
      if (this.isSelected) {
        if (this.isSelected === 2) {
          this.$emit("settingCondition", this.isSelected);
          this.hide();
        } else {
          this.$v.form.$touch();
          var error = [];
          for (let n = 0; n < this.form.length - 1; n++) {
            if (this.form[n].operation_type != "") {
              error[n] = false;
            } else error[n] = true;
          }
          this.errorGroup = error;

          if (this.$v.form.$error || !error.every((el) => el == false)) {
            return;
          }
          this.$emit("setForm", this.form);

          var obj = this.form.filter(
            (el) =>
              el.condition_type_id != 12 &&
              el.condition_type_id != 13 &&
              el.condition_type_id != 14
          );

          let objTemp = obj;
          objTemp = objTemp.filter((el) => el.condition_value);
          this.$emit("settingCondition", this.isSelected, objTemp);
          this.hide();
        }
      } else {
        this.$swal("Please set conditions", {
          icon: "warning",
        });
      }
    },
    setCondition() {
      if (this.isSelected === 1) {
        if (this.form.length == 0) this.addGroupConditions();
        else if (this.form[0].condition.length < 1) this.addRuleCondition(0);
        // this.form = JSON.parse(JSON.stringify(this.listCondition));
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    onFileChange(...file) {
      this.filename = file[0].name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = () => {
        this.form[file[2]].import_condition = reader.result.substring(
          str.length,
          reader.result.length
        );
        this.checkFormFile(file);
      };
    },
    async checkFormFile(file) {
      this.isLoading = true;
      let body = {
        excel_file: this.form[file[2]].import_condition,
      };
      await this.$store.dispatch("checkFileExcel", body);
      const data = this.$store.state.automation.stateCheckFileExcel;
      if (data.result === 1) {
        if (data.detail > 0) {
          this.setFormFile(file);
        } else {
          this.isLoading = false;
          this.form[file[2]].import_condition = "";
          this.filename = null;
          const msg = "Please check the information throughly";
          this.$swal(msg, {
            icon: "warning",
          });
        }
      } else {
        this.isLoading = false;
        this.form[file[2]].import_condition = "";
        this.filename = null;
        this.$swal(data.message, {
          icon: "warning",
        });
      }
    },
    setFormFile(file) {
      this.isLoading = false;

      this.form[file[2]].condition[file[1]].condition_value = file[0].name;
      this.form[file[2]].condition[file[1]].operation_type_id = 3;
    },
    deleteFile(indexGroup, indexCondition) {
      this.filename = null;
      this.form[indexGroup].import_condition = "";
      this.form[indexGroup].condition[indexCondition].condition_value = "";
      this.isDisable = true;
    },
    setOperation(...value) {
      this.form[value[3]].operation_type = value[0];
    },
    async onImportFileChange(file, groupIndex, index, id) {
      this.$EventBus.$emit("showLoading");
      this.fileUpload.name = file.name;

      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      let value = null;
      reader.readAsDataURL(file);
      reader.onload = async () => {
        value = reader.result.substring(str.length, reader.result.length);
        this.fileUpload.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
        let body = {
          excel_file: value,
        };
        let url = "";
        if (id == 17) {
          url = `${process.env.VUE_APP_API}/MarketingAutomation/importproductcondition`;
        } else {
          url = `${process.env.VUE_APP_API}/MarketingAutomation/importservicecondition`;
        }
        const res = await this.$Axios.post(url, body);

        if (this.form[groupIndex].condition[index].condition_value != "") {
          this.form[groupIndex].condition[index].condition_value = [
            ...this.form[groupIndex].condition[index].condition_value,
            ...res.data.detail.map((el) => String(el)),
          ];
        } else
          this.form[groupIndex].condition[index].condition_value =
            res.data.detail.map((el) => String(el));

        this.form[groupIndex].condition[index].condition_value = [
          ...new Set(this.form[groupIndex].condition[index].condition_value),
        ];
        this.form[groupIndex].condition[index].operation_type_id = 3;
        this.fileUpload.name = null;
        this.fileUpload.excel_file = "";
        this.$EventBus.$emit("hideLoading");
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  color: var(--font-color);
  font-weight: 600;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}

::v-deep .b-sidebar {
  width: 450px;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
.bg-color {
  background-color: var(--primary-color);
}
.btn-add {
  background-color: transparent;
  border: none;
  color: var(--primary-color);
}
.btn-add:disabled {
  opacity: 0.5;
}
.add-new {
  text-decoration: underline;
}
::v-deep .btn-light {
  background-color: transparent;
  border: none;
}
.display-inline {
  display: inline-block;
}
::v-deep .btn-secondary {
  background-color: #fff;
  border-color: #fff;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.text-error {
  color: red;
}
.border-error {
  border-color: red;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
::v-deep .date-picker .vdatetime-input {
  margin-bottom: 0;
  padding: 2px 10px;
}
.card-condition-container {
  background: var(--secondary-color);
  padding: 0.75rem;
  margin-bottom: 0.5rem;
}
.card-group-title {
  color: var(--primary-color);
  font-weight: bold;
}
</style>
